<template>
    <div>
        <div v-if="authUserPermission['sap-sync-menus-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('sap_sync_menus') }}</h3>
                            </div>
                            <div class="col-6">
                                <base-input input-classes="form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search')" v-on:keyup="filter"/>
                            </div>
                            <div class="col text-right">
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['sap-sync-menus-create']">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('menu')" :prop="tt('menu')" min-width="170px" sortable>
                                <template v-slot="{row}">
                                    {{row.menu}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('sap_sync_id')" :prop="tt('sap_sync_id')" min-width="170px" sortable>
                                <template v-slot="{row}">
                                    {{row.sap_sync_id}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('key')" :prop="tt('key')" min-width="170px" sortable>
                                <template v-slot="{row}">
                                    {{row.key}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('icon')" :prop="tt('icon')" min-width="170px" sortable>
                                <template v-slot="{row}">
                                    {{row.icon}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('attributes')" :prop="tt('attributes')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.attributes}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('sequence')" :prop="tt('sequence')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.sequence}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('parent_id')" :prop="tt('parent_id')" min-width="190px" sortable>
                                <template v-slot="{row}">
                                    {{row.parent_id}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['sap-sync-menus-update'] || authUserPermission['sap-sync-menus-delete']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['sap-sync-menus-update']">{{ tt('edit') }}</el-dropdown-item>
                                            <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['sap-sync-menus-delete']">{{ tt('delete') }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>
                        <label class="form-control-label">{{ tt('menu') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('menu')" :placeholder="tt('menu')" v-model="sapSyncMenus.menu" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('sap_sync_id') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('company_code')" rules="required">
                            <el-select class="select-danger" v-model="sapSyncMenus.sap_sync_id" :placeholder="tt('choose_plant')">
                                <el-option class="select-danger" :value="ssm['id']" :label="ssm['id']" :key="ssm['id']" v-for="ssm in  sapSyncMaster"></el-option>
                            </el-select>
                        </base-input>

                        <label class="form-control-label">{{ tt('key') }}</label>
                        <base-input :name="tt('key')" :placeholder="tt('key')" v-model="sapSyncMenus.key"></base-input>

                        <label class="form-control-label">{{ tt('icon') }} </label>
                        <base-input :name="tt('icon')" :placeholder="tt('icon')" v-model="sapSyncMenus.icon"></base-input>

                        <label class="form-control-label">{{ tt('attributes') }} </label>
                        <base-input :name="tt('attributes')" :placeholder="tt('attributes')" v-model="sapSyncMenus.attributes"></base-input>

                        <label class="form-control-label">{{ tt('sequence') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('sequence')" :placeholder="tt('sequence')" v-model="sapSyncMenus.sequence" rules="required|numeric"></base-input>

                        <label class="form-control-label">{{ tt('parent_id') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('parent_id')" :placeholder="tt('parent_id')" v-model="sapSyncMenus.parent_id" rules="required|numeric"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import sapSyncMenus from '@/services/sap/sapSyncMenus.service';
    import sapSyncMaster from '@/services/sap/sapSyncMaster.service';

    export default {        
        data() {
            return { 
                onLoad: true, 
                loadTimeout: null, 
                errorMessage: null,
                btnSave: {
                    onLoading: false
                },                  
                form: {
                    add: true,
                    title: this.tt('add_sap_synchron_master'),
                    show: false
                }, 
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },        
                sapSyncMenus: {}, 
                sapSyncMaster: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            this.getSapSyncMaster()
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            get() { 
                let context = this;               
                Api(context, sapSyncMenus.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.data.total;
                    context.table.data  = response.data.data.data.data;
                    context.table.page  = response.data.data.data;  
                    context.onLoad      = false;      
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data  = [];
                        context.table.total = 0;
                        context.onLoad      = false;
                    }
                })
                .call()
            },
            filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add');
                this.form.show = true;
                this.sapSyncMenus = {};
            },
            edit(id) {
                this.errorMessage = null;
                let context       = this;   

                Api(context, sapSyncMenus.show(id)).onSuccess(function(response) {
                    context.sapSyncMenus = response.data.data;
                    context.form.add      = false;
                    context.form.title    = context.tt('edit');
                    context.form.show     = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api     = null;
                let context = this;

                if (this.form.add) {
                    api = Api(context, sapSyncMenus.create(this.sapSyncMenus));
                } else {{
                    api = Api(context, sapSyncMenus.update(this.sapSyncMenus.id, this.sapSyncMenus));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, sapSyncMenus.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            getSapSyncMaster(){
                let context = this;               
                Api(context, sapSyncMaster.get(null, {per_page: 'none'})).onSuccess(function(response) {
                    context.sapSyncMaster = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.sapSyncMaster = [];
                    }
                })
                .call()
            }
        }   
    };
</script>
<style></style>
